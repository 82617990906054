import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthLoginRoute, AuthRequestMfRoute } from "@/module/auth/page";
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import {
  AccountReferralCode,
  BrandedKinvault,
  IntroducerCompany,
  IntroducerContact,
} from "@kinherit/sdk";
import { defineComponent } from "vue";

export type Pages =
  | "default"
  | "admin"
  | "assets"
  | "care"
  | "contacts"
  | "death"
  | "legal"
  | "misc"
  | "settings"
  | "setup-wizard";

export type Permissions = "read" | "write";

export class AuthService {
  public static mixin({
    module,
  }: {
    module: "partner-portal" | "logged-out" | "logged-in";
    page?: Pages;
  }) {
    return defineComponent({
      name: "AuthMixin",
      computed: {
        $auth: () =>
          AuthService as {
            activeSession: typeof AuthService.activeSession;
            activeIntroducerContact: typeof AuthService.activeIntroducerContact;
            portalPermissions: typeof AuthService.portalPermissions;
            activeIntroducerCompany: typeof AuthService.activeIntroducerCompany;
            activeDefaultReferralCode: typeof AuthService.activeDefaultReferralCode;
            activeDefaultBrandedKinvault: typeof AuthService.activeDefaultBrandedKinvault;
          },
      },
      async beforeMount(): Promise<void> {
        const hasSession = this.$auth.activeSession;
        const hasMfAuth = this.$auth.activeSession?.mfaRequired === false;

        if (module === "logged-in") {
          if (!hasSession) {
            window.Kernel.visitRoute({
              name: AuthLoginRoute,
            });
            return;
          } else if (!hasMfAuth) {
            window.Kernel.visitRoute({
              name: AuthRequestMfRoute,
            });
            return;
          }
        }

        if (module === "logged-out") {
          if (hasSession && hasMfAuth) {
            window.Kernel.visitRoute({
              name: PartnerPortalIndexRoute,
            });
          }
        }

        if (module === "partner-portal") {
          if (!hasSession) {
            window.Kernel.visitRoute({
              name: AuthLoginRoute,
            });
            return;
          } else if (!hasMfAuth) {
            window.Kernel.visitRoute({
              name: AuthRequestMfRoute,
            });
            return;
          }
        }
      },
    });
  }

  public static get activeSession(): ActiveSession | null {
    return ActiveSession.$getInstance();
  }

  public static get activeIntroducerContact(): IntroducerContact | null {
    const contactId = AuthService.activeSession?.user;
    if (undefined === contactId) {
      return null;
    }

    return IntroducerContact.$findOne(contactId);
  }

  public static get activeIntroducerCompany(): IntroducerCompany | null {
    const contact = this.activeIntroducerContact;

    if (!contact) {
      return null;
    }

    return contact.companies.first() ?? null;
  }

  public static get activeDefaultReferralCode(): AccountReferralCode | null {
    const company = this.activeIntroducerCompany;

    const code = company?.referralCodes?.first();

    if (!code) {
      return null;
    }

    return code;
  }
  public static get activeDefaultBrandedKinvault(): BrandedKinvault | null {
    const company = this.activeIntroducerCompany;

    const brandedKinvault = company?.brandedKinvaultOwnerships.first();

    if (!brandedKinvault) {
      return null;
    }

    return brandedKinvault;
  }

  public static get portalPermissions(): {
    clientsRead: "any" | "own" | "none";
    clientsCreate: boolean;
    feesRead: "any" | "own" | "none";
    company: IntroducerCompany | null;
    viewOrderProducts: boolean;
    enableStandaloneKinvault: boolean;
    invitationsCreate: boolean;
    allowKinvaultAccess: boolean;
    allowKinvaultAccessRequest: boolean;
  } {
    const contact = this.activeIntroducerContact;

    if (!contact) {
      return {
        clientsRead: "none",
        clientsCreate: false,
        feesRead: "none",
        company: null,
        viewOrderProducts: false,
        enableStandaloneKinvault: false,
        invitationsCreate: false,
        allowKinvaultAccess: false,
        allowKinvaultAccessRequest: false,
      };
    }

    const company = this.activeIntroducerCompany;

    const companyPermissions = company?.companyPartnerPortalPermissions;

    if (companyPermissions && false === companyPermissions.allowPartnerPortal) {
      return {
        clientsRead: "none",
        clientsCreate: false,
        feesRead: "none",
        company,
        viewOrderProducts: false,
        enableStandaloneKinvault: false,
        invitationsCreate: false,
        allowKinvaultAccess: false,
        allowKinvaultAccessRequest: false,
      };
    }

    const contactPermissions = contact.partnerPortalPermissions.first();

    let clientsRead: "any" | "own" | "none" | null = "none";
    let clientsCreate = false;
    let feesRead: "any" | "own" | "none" | null = "none";
    let viewOrderProducts = false;
    let enableStandaloneKinvault = false;
    let invitationsCreate = false;
    let allowKinvaultAccess = false;
    let allowKinvaultAccessRequest = false;

    if (contactPermissions) {
      clientsRead = contactPermissions.clientsRead;
      clientsCreate = contactPermissions.clientsCreate;
      feesRead = contactPermissions.feesRead;
      invitationsCreate = contactPermissions.invitationsCreate;
      allowKinvaultAccessRequest =
        contactPermissions.allowKinvaultAccessRequest;
    }

    if (companyPermissions) {
      if (false === companyPermissions.enableClients) {
        clientsRead = "none";
        clientsCreate = false;
      }

      if (false === companyPermissions.enableFees) {
        feesRead = "none";
      }

      viewOrderProducts = companyPermissions.viewOrderProducts;
      enableStandaloneKinvault = companyPermissions.enableStandaloneKinvault;
      allowKinvaultAccess = companyPermissions.allowKinvaultAccess;
    }

    return {
      clientsRead,
      clientsCreate,
      feesRead,
      company,
      viewOrderProducts,
      enableStandaloneKinvault,
      invitationsCreate,
      allowKinvaultAccess,
      allowKinvaultAccessRequest,
    };
  }
}
