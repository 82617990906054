import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultAccessRequest } from "@kinherit/sdk/index";

export const UpdateKinvaultAccessRequest = DefineWriteAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/access-request/{kinvaultAccessRequest}",
  method: "patch",
  parse: (message: KinvaultAccessRequest) => ({
    body: {
      company: message.$data.company,
      contact: message.$data.contact,
      kintin: message.$data.kintin,
      status: message.$data.status,
    },
    params: {
      kinvaultAccessRequest: message,
    },
  }),
});
