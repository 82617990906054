import {
  BrandedKinvaultField,
  IntroducerContactField,
  ReferralCodeField,
} from "@/config/form.config";
import { AuthService } from "@/service/auth.service";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm/index";
import {
  AccountReferralCode,
  BrandedKinvault,
  IntroducerContact,
} from "@kinherit/sdk/index";

type InviteFormData = {
  firstName: string;
  lastName: string;
  email: string;
  externalRef: string | null;
  referralCode: AccountReferralCode | null;
  adviser: IntroducerContact | null;
  sendInvitationEmail: boolean | null;
  acknowledgeCharge: boolean | null;
  brandedKinvault: BrandedKinvault | null;
};
export const CreateInviteForm = () =>
  defineForm({
    name: "create-invite-form",
    data: (): InviteFormData => ({
      firstName: "",
      lastName: "",
      email: "",
      externalRef: null as null | string,
      referralCode: AuthService.activeDefaultReferralCode ?? null,
      adviser: AuthService.activeIntroducerContact ?? null,
      sendInvitationEmail: null as null | boolean,
      acknowledgeCharge: null as null | boolean,
      brandedKinvault: AuthService.activeDefaultBrandedKinvault ?? null,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-invite-form-area",
        data,
        template: GridLayout([
          ["introText"],
          ["firstName", "lastName"],
          ["email"],
          ["referralText"],
          ["referralCode", "brandedKinvault"],
          ["adviser", "externalRef"],
          ["invitationText"],
          ["sendInvitationEmail"],
          ["acknowledgeCharge"],
        ]),
        components: () => ({
          introText: [
            FormTitle({
              props: {
                text: "Client Details",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
                class: "first-name-field",
                reference: "firstName",
              },
              models: {
                value: "firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
                class: "last-name-field",
                reference: "lastName",
              },
              models: {
                value: "lastName",
              },
            }),
          ],
          email: [
            FormEmailField({
              props: {
                label: "Email",
                validators: ["required"],
                class: "email-field",
                reference: "email",
              },
              models: {
                value: "email",
              },
            }),
          ],
          referralText: [
            FormTitle({
              props: {
                text: "Referral Details",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          externalRef: [
            FormTextField({
              props: {
                label: "Your Reference",
                message: "Your reference for this client (optional)",
                class: "external-ref-field",
                reference: "externalRef",
              },
              models: {
                value: "externalRef",
              },
            }),
          ],
          referralCode: [
            ReferralCodeField({
              props: {
                label: "Referral Code",
                message: "Please select your referral code",
                class: "referral-code-field",
                vModel: "referralCode",
                validators: ["required"],
                reference: "referralCode",
              },
              query: {
                company: {
                  id: Equal(AuthService.activeIntroducerCompany?.id),
                },
              },
            }),
          ],
          adviser: [
            IntroducerContactField({
              props: {
                label: "Adviser",
                message: "Please select this client's adviser (optional)",
                vModel: "adviser",
                class: "adviser-field",
                reference: "adviser",
              },
              query: {
                companies: {
                  id: Equal(AuthService.activeIntroducerCompany?.id),
                },
              },
            }),
          ],
          invitationText: [
            FormTitle({
              props: {
                text: "Invitation Preferences",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          sendInvitationEmail: [
            FormRadioField({
              props: {
                label: "Send invitation email",
                options: [
                  "No - I will send this myself",
                  "Yes - Kinvault should send an invitation email",
                ],
                validators: ["required"],
                direction: "is-vertical",
                class: "send-invitation-email-field",
                reference: "sendInvitationEmail",
              },
              models: {
                value: "sendInvitationEmail",
              },
            }),
          ],
          acknowledgeCharge: [
            FormCheckboxField({
              props: {
                label:
                  "I acknowledge that by creating this invitation, that my organisation will be charged on an annual basis should the client choose to create an account",
                validators: ["checked"],
                class: "acknowledge-charge-field",
                reference: "acknowledgeCharge",
              },
              models: {
                value: "acknowledgeCharge",
              },
            }),
          ],
          brandedKinvault: [
            BrandedKinvaultField({
              props: {
                label: "Branded Kinvault",
                message: "Please select your branded Kinvault",
                vModel: "brandedKinvault",
                validators: ["required"],
                class: "branded-kinvault-field",
                reference: "brandedKinvault",
              },
              query: [
                {
                  ownerCompanies: {
                    id: Equal(AuthService.activeIntroducerCompany?.id),
                  },
                },
                {
                  memberCompanies: {
                    id: Equal(AuthService.activeIntroducerCompany?.id),
                  },
                },
              ],
            }),
          ],
        }),
      }),
    ],
  });
