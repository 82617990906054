import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultAccessRequest } from "@kinherit/sdk/index";

export const ReadKinvaultAccessRequest = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/access-request",
  rootModel: KinvaultAccessRequest,
  method: "get",
  parse: () => ({}),
});
