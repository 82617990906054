import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultAccessRequest } from "@kinherit/sdk/index";

export const CreateKinvaultAccessRequest = DefineWriteAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/access-request",
  method: "post",
  parse: (message: KinvaultAccessRequest) => ({
    body: {
      company: message.$data.company,
      contact: message.$data.contact,
      kintin: message.$data.kintin,
      status: message.$data.status,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
