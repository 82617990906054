<template>
  <div class="columns is-centered">
    <div class="column is-6-tablet is-4-desktop">
      <Card v-if="state === 'change'" title="Other Ways to Login">
        <p>Try one of these alternative methods to authenticate.</p>
        <RadioField
          :options="session?.mfMethods.pluck('id') ?? []"
          :value="method?.id ?? null"
          @update:value="
            (index: number) => (method = session?.mfMethods[index] ?? null)
          "
          direction="is-vertical"
        >
          <template #label="{ option }">
            <b v-if="session?.mfMethods.findBy('id', option)?.alias">
              {{
                session?.mfMethods.findBy("id", option)?.alias ??
                "Authenticator"
              }}
            </b>
            <br />
            <small>
              {{ session?.mfMethods.findBy("id", option)?.type }}
            </small>
          </template>
        </RadioField>

        <div class="buttons">
          <Button
            @click="getCode"
            :is-disabled="null === method"
            color="is-positive"
            is-fullwidth
          >
            Continue
          </Button>
        </div>
      </Card>
      <Card
        v-if="state === 'verify'"
        title="Additional Authentication Required"
      >
        <p>{{ verifyTitle }}</p>
        <PinField
          v-model:value="code"
          :label="label"
          :is-fullwidth="false"
          align="center"
          @submit="verify"
        />
        <div class="buttons">
          <Button
            class="verify-button"
            @click="verify"
            color="is-positive"
            :is-fullwidth="true"
            :is-disabled="(code ?? '').length !== 6"
          >
            Verify
          </Button>
        </div>
        <template #footer>
          <div class="level is-fullwidth">
            <div
              v-if="method?.type === 'sms' || method?.type === 'email'"
              class="level-right"
            >
              <small>
                <component
                  :is="canResendCode ? 'a' : 'span'"
                  href="#"
                  :class="{
                    'is-link': canResendCode,
                    'is-clickable': canResendCode,
                  }"
                  @click="getResend"
                >
                  {{
                    canResendCode
                      ? "Code didn't arrive? Resend code"
                      : "Sending code - Please wait"
                  }}
                  <div
                    v-if="!canResendCode"
                    class="loader is-inline-block ml-5"
                  ></div>
                </component>
              </small>
            </div>
            <div v-if="hasMultipleMethods" class="level-left">
              <small>
                <a href="#" class="is-link is-clickable" @click="changeMethods">
                  {{ changeMethodsLabel }}
                </a>
              </small>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PinField } from "@kinherit/framework/component.input/pin-field";
import RadioField from "@kinherit/framework/component.input/radio-field";
import { Card } from "@kinherit/framework/component.layout/card";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { AuthRequestMfParams, AuthRequestMfRoute } from ".";
import { ActiveSession } from "../model/active-session.model";
import { Session } from "../model/session.model";

export default defineComponent({
  name: AuthRequestMfRoute,
  components: { Card, Button, PinField, RadioField },
  mixins: [
    AuthService.mixin({
      module: "logged-out",
    }),
  ],
  data: () => ({
    method: null as null | Session["mfMethods"][number],
    multiFactorSessionId: null as null | string,
    code: null as null | string,
    successful: false,
    methodPriority: ["totp", "sms", "email"],
    canResendCode: false,
    resendCodeCooloff: 20_000,
  }),
  computed: {
    session(): Session | null {
      const params: AuthRequestMfParams = this.$route.params as any;

      const sessions = Session.$findBy({
        username: params.username,
      });

      return sessions[0] ?? null;
    },
    state(): "change" | "verify" {
      if (null === this.multiFactorSessionId) {
        return "change";
      }

      return "verify";
    },
    verifyTitle(): string {
      if (this.method?.type === "totp") {
        return `Please enter the six digit code from your authenticator app to continue.`;
      }

      if (this.method?.type === "sms") {
        return `Please enter the six digit code sent to your phone to continue.`;
      }

      if (this.method?.type === "email") {
        return `Please check your email for the six digit code to continue.`;
      }

      return "";
    },
    label(): string {
      let label = this.method?.alias ?? "";

      switch (this.method?.type) {
        case "totp":
          if (label === "") {
            label = "Authenticator";
          }
          break;
        case "sms":
          label += `${label === "" ? "Email Address " : " "}(${this.method?.device})`;
          break;
        case "email":
          label += `${label === "" ? "Phone Number " : " "}(${this.method?.device})`;
          break;
      }

      return label;
    },
    hasMultipleMethods(): boolean {
      return (this.session?.mfMethods.length ?? 0) > 1;
    },
    changeMethodsLabel(): string {
      if (this.alternateMethod?.type === "totp") {
        return "Login with the authenticator app";
      }

      if (this.alternateMethod?.type === "sms") {
        return "Login with a phone number";
      }

      if (this.alternateMethod?.type === "email") {
        return "Login with an email address";
      }

      return "Other Ways to Authenticate";
    },
    alternateMethod(): Session["mfMethods"][number] | null {
      if ((this.session?.mfMethods.length ?? 0) !== 1) {
        return null;
      }

      return (
        this.session?.mfMethods.find((m) => m.id !== this.method?.id) ?? null
      );
    },
  },
  mounted(): void {
    if (null === this.session) {
      return;
    }

    ActiveSession.fromSession({
      ...this.session.$data,
    }).$persist();

    for (const method of this.methodPriority) {
      if (this.session.mfMethods.some((m) => m.type === method)) {
        this.method =
          this.session.mfMethods.find((m) => m.type === method) ?? null;
        break;
      }
    }

    if (null !== this.method) {
      this.getCode();
    }
  },
  beforeRouteLeave(): void {
    if (false === this.successful) {
      ActiveSession.$getInstance()?.$delete();
    }
  },
  methods: {
    async getResend(): Promise<void> {
      if (this.method?.type === "email") {
        await OpenAlertDialog({
          dialog: {
            title: "Resend Code",
            message: `Please check your spam folder to ensure the email has not been blocked by your email provider.`,
          },
          button: {
            ok: {
              text: "Resend",
            },
            cancel: {
              text: "Back",
            },
          },
        });
      }

      if (this.canResendCode) {
        await this.getCode();
      }
    },
    async getCode(): Promise<void> {
      if (null === this.method || null === this.session) {
        return;
      }

      // const result = await window.Kernel.ActionBus.execute(
      //   "auth/multi-factor/request",
      //   {
      //     methodId: this.method.id,
      //     session: this.session,
      //   },
      // );
      const result = await window.Kernel.ActionBus.auth.multiFactor.request({
        methodId: this.method.id,
        session: this.session,
      });

      this.multiFactorSessionId = result.multiFactorSessionId;
      this.code = result.code?.toString() ?? null;
      this.canResendCode = false;

      setTimeout(() => {
        this.canResendCode = true;
      }, this.resendCodeCooloff);
    },
    async verify(): Promise<void> {
      if (
        null === this.code ||
        null === this.session ||
        null === this.multiFactorSessionId
      ) {
        return;
      }

      // const { session } = await window.Kernel.ActionBus.execute(
      //   "auth/multi-factor/complete",
      //   {
      //     code: Number.parseInt(this.code),
      //     multiFactorSessionId: this.multiFactorSessionId,
      //     session: this.session,
      //   },
      // );
      const { session } =
        await window.Kernel.ActionBus.auth.multiFactor.complete({
          code: Number.parseInt(this.code),
          multiFactorSessionId: this.multiFactorSessionId,
          session: this.session,
        });

      ActiveSession.fromSession({
        ...session.$data,
      }).$persist();

      this.successful = true;

      if (this.$route.query.redirect) {
        window.Kernel.visitRoute({
          path: this.$route.query.redirect as string,
          query: Object.omit(this.$route.query, ["redirect"]),
        });
      } else {
        window.Kernel.visitRoute({
          name: PartnerPortalIndexRoute,
        });
      }
    },
    async changeMethods(): Promise<void> {
      this.method = this.alternateMethod;
      this.canResendCode = false;

      if (null !== this.method) {
        await this.getCode();
        return;
      }

      this.multiFactorSessionId = null;
    },
  },
});
</script>
