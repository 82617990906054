import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KinvaultAccessRequest } from "@kinherit/sdk/index";

export const DeleteKinvaultAccessRequest = DefineDeleteAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/access-request/{kinvaultAccessRequest}",
  method: "delete",
  parse: (message: KinvaultAccessRequest) => ({
    params: {
      kinvaultAccessRequest: message,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
